import React, { useState, useEffect } from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
// List page
import Seo from "@components/seo"
import "../styles/index.scss"
import { isLoggedIn } from "../utility/Auth";
import { navigate } from "@reach/router";

const IndexPage = () => {
  const [isAuth, setIsAuth] = useState(isLoggedIn());
  useEffect(async () => {
    if (isAuth) {
      //Fetch offices of logged in user
      navigate('/agents/list');
    } else {
      navigate('/auth/login');
    }
  }, [isAuth]);

  return (
    <>
      <Seo title="Resiland" />
      <div className="main-wrapper">
        <Sidebar menuActive="agents" />
      </div>
    </>
  )
}

export default IndexPage
